import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

// components can be called from the imported UIkit reference



let bar = document.getElementById('js-progressbar');

UIkit.upload('.js-upload', {

 url: 'assets/2021/tmp/snip/prev.php',
 multiple: false,
 allow: '*.(jpg|jpeg|gif|png)',
 beforeSend: function (environment) {
  console.log('beforeSend', arguments);

  // The environment object can still be modified here.
  // var {data, method, headers, xhr, responseType} = environment;

 },
 beforeAll: function () {
  //console.log('beforeAll', arguments);
 },
 load: function () {
 // console.log('load', arguments);
 },
 error: function () {
  console.log('error', arguments);
 },
 complete: function () {


  $('#prev').css('display','');
  $('#prev').attr('src',arguments[0].response);
  $('#images').val(arguments[0].response);

 },

 loadStart: function (e) {
 // console.log('loadStart', arguments);

  bar.removeAttribute('hidden');
  bar.max = e.total;
  bar.value = e.loaded;
 },

 progress: function (e) {
 // console.log('progress', arguments);

  bar.max = e.total;
  bar.value = e.loaded;
 },

 loadEnd: function (e) {
 // console.log('loadEnd', arguments);

  bar.max = e.total;
  bar.value = e.loaded;
 },

 completeAll: function () {
  //console.log('completeAll', arguments);

  setTimeout(function () {
   bar.setAttribute('hidden', 'hidden');
  }, 1000);


 }

});



var params = (new URL(document.location)).searchParams;
var urlAjax = "assets/2021/tmp/snip/create.php"
if (params.get("id") > 0) {
 localStorage.setItem('pagetitle', '');
 localStorage.setItem('content', '');
 urlAjax = "assets/2021/tmp/snip/update.php"
}




function sendForm()
{

 if($('#pagetitle').val() == ''){
  UIkit.notification('Заполните заголовок');
  return false;
 }
// if($('#images').val() == ''){
 // UIkit.notification('Добавьте обложку');
 // return false;
 //}

 localStorage.setItem('pagetitle', '');
 localStorage.setItem('content', '');

 $('#publik').css('display','none');
 $.ajax({
  url: urlAjax,
  type: 'post',
  data: $('#create').serialize()
 }).done(function(data) {
  window.location.href = data;
 });
}



$( "#publik" ).click(function() {
 sendForm();
});



function addEcpDrop() {
 $( ".nored .ecp" ).each(function( index ) {

   $(this).after('<div data-uk-drop><div class="ecp-card uk-card uk-card-body uk-card-default uk-padding-small"><div class="uk-h4">Электронная подпись</div>Дата и время подписи: '+$(this).data("time")+'<br>Владелец сертификата: '+$(this).data("fullname")+'<br> Должность:  '+$(this).data("position")+'<br> Подпись: '+$(this).data("code").substr(1, 25)+'</div></div>');

 });
}
$( ".calendar" ).addClass('uk-table')
addEcpDrop();


$( ".text-main img" ).each(function( index ) {
 if($( this ).height()  > 600) {
  if($( this ).height() > $( this ).width())
   $(this).parent('figure').addClass('imagelong');
 }
});


const urlAjaxDel = "assets/2021/tmp/snip/delete.php";

UIkit.util.on('#js-modal-confirm', 'click', function (e) {
 e.preventDefault();
 e.target.blur();
 UIkit.modal.confirm('Вы хотите  удалить эту страницу?', { labels: { ok: 'Да', cancel: 'Нет' } }).then(function () {
  let idRes  =  $('#js-modal-confirm').data('id')


  $.ajax({
   url: urlAjaxDel,
   type: 'post',
   data: {idres: idRes},
  }).done(function(data) {

   window.location.href = '/';
  });

 }, function () {
  console.log('Rejected.')
 });
});


const urlAjaxDelImage = "assets/2021/tmp/snip/deleteImageGallery.php";

$("#gallery").on("click", ".deleteimage", function() {


  let idx  =  $(this).data('idx')
  console.log(idx)
  $.ajax({
   url: urlAjaxDelImage,
   type: 'post',
   data: {idres: params.get("id"), idx: idx},
  }).done(function(data) {
   $('#gallery').html(data);

  });



});



let bar1 = document.getElementById('js-progressbar1');

UIkit.upload('.js-upload1', {

 url: 'assets/2021/tmp/snip/gallery.php',
 multiple: true,
 params: {"id": params.get("id")},
 allow: '*.(jpg|jpeg|gif|png)',
 beforeSend: function (environment) {
  console.log('beforeSend', arguments);

  // The environment object can still be modified here.
  // var {data, method, headers, xhr, responseType} = environment;

 },
 beforeAll: function () {
  //console.log('beforeAll', arguments);
 },
 load: function () {
  // console.log('load', arguments);
 },
 error: function () {
  console.log('error', arguments);
 },
 complete: function () {

console.log(arguments);
 // $('#prev').css('display','');
 // $('#prev').attr('src',arguments[0].response);
  $('#gallery').html(arguments[0].response);

 },

 loadStart: function (e) {
  // console.log('loadStart', arguments);

  bar1.removeAttribute('hidden');
  bar1.max = e.total;
  bar1.value = e.loaded;
 },

 progress: function (e) {
  // console.log('progress', arguments);

  bar1.max = e.total;
  bar1.value = e.loaded;
 },

 loadEnd: function (e) {
  // console.log('loadEnd', arguments);

  bar1.max = e.total;
  bar1.value = e.loaded;
 },

 completeAll: function () {
  //console.log('completeAll', arguments);

  setTimeout(function () {
   bar1.setAttribute('hidden', 'hidden');
  }, 1000);


 }

});
